module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-82BBQDXF32"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Policy Lab","short_name":"The Policy Lab","start_url":"/","background_color":"#007ea8","theme_color":"#007ea8","display":"standalone","icon":"src/images/favicons/favicon-256x256.png","include_favicon":true,"crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","cacheDigest":"da6b2ba59319df55c77b74d88200cebe"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"elements":["h2"],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"wag1nll"},"custom":{"families":["Gotham Narrow A","Gotham Narrow B","Gotham SSm A","Gotham SSm B"],"urls":["https://cloud.typography.com/7022116/7515612/css/fonts.css"]}},
    }]
